import { Page, PageTitle } from "./PageContent";

export const PrivacyPage: React.FC = () => {
    return <Page auth={false}>
        <PageTitle>Privacy Policy</PageTitle>
        <p><b>Data storage and availability:</b> Data entered into the app (Dewey) will be stored in a Google Sheet created by Dewey on your Drive account, and you can access it directly at any time. Dewey is only capable of accessing files that it created, so it has no access to any of your other data on Drive. Your Dewey spreadsheet will have link sharing enabled, meaning anyone with access to the link to the spreadsheet will be able to view its contents (but not edit or comment).</p>
        <p><b>Data content:</b> Dewey stores (in the Google Sheet) your public Google account information (name, email, and profile photo URL). Dewey also stores information you provide to it, e.g. a list of books you've added to your Dewey Library, a list of books you've added to your Dewey Wishlist, information about each of those books (title, author, etc.), your book reviews, and a list of friends you've added.</p>
        <p><b>Local storage:</b> Dewey stores your email and spreadsheet ID in local storage in your browser to facilitate loading your spreadsheet and logging you in.</p>
        <p><b>Ownership of data:</b> You may view, edit, and delete any data stored by Dewey in your spreadsheet by accessing your Drive account (link on your Dewey Profile page). You may also delete all data by deleting the spreadsheet.</p>
        <p><b>Google disclaimer:</b> Dewey is not affiliated with Google.</p>
    </Page>;
}