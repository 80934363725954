import { Page, PageTitle, Link, ButtonLink } from "./PageContent";

export const HomePage: React.FC = () => {
    return <Page auth={false}>
        <PageTitle>Welcome to Dewey</PageTitle>
        <p><b>The Distributed Lending Library</b></p>
        <p>Dewey turns your friend network into a decentralized library by helping you keep track of the books in your library and on your wishlist and showing when your friends have the books you want, and vice versa.</p>
        <ButtonLink to="/setup">Get Started</ButtonLink>
        <p>
            Your library is stored in a Google Sheet created by Dewey on your account, giving you full visibility into the data Dewey stores and complete access to download or manage it however you choose. Dewey does not have access to any other files on your Google Drive account. For more info, see the <Link to="/privacy">Privacy Policy</Link>.
        </p>
        <p>
            By accessing or using Dewey, you agree to the <Link to="/terms">Terms of Service</Link>.
        </p>
    </Page>;
}