import agreement from "./assets/icons8/icons8-agreement-64.png"
import info from "./assets/icons8/icons8-info-64.png"
import privacy from "./assets/icons8/icons8-privacy-64.png"
import minus from "./assets/icons8/icons8-subtract-64.png"
import book from "./assets/icons8/icons8-book-64.png"
import loading from "./assets/icons8/icons8-loader-64.png"
import read from "./assets/icons8/icons8-read-64.png"
import friends from "./assets/icons8/icons8-team-64.png"
import review from "./assets/icons8/icons8-book-and-pencil-64.png"   
import menu from "./assets/icons8/icons8-menu-64.png"
import search from "./assets/icons8/icons8-search-64.png"
import thumbsDown from "./assets/icons8/icons8-thumbs-down-64.png"
import library from "./assets/icons8/icons8-book-shelf-64.png"        
import bell from "./assets/icons8/icons8-notification-64.png"      
import security from "./assets/icons8/icons8-security-lock-64.png"     
import thumbsUp from "./assets/icons8/icons8-thumbs-up-64.png"
import trash from "./assets/icons8/icons8-delete-64.png"
import ok from "./assets/icons8/icons8-ok-hand-64.png"
import give from "./assets/icons8/icons8-sell-64.png"
import heart from "./assets/icons8/icons8-heart-64.png"
import plus from "./assets/icons8/icons8-add-64.png"
import soSo from "./assets/icons8/icons8-so-so-64.png"
import logOut from "./assets/icons8/icons8-logout-64.png"
import cancel from "./assets/icons8/icons8-cancel-64.png"
import bookmark from "./assets/icons8/icons8-bookmark-64.png"
import important from "./assets/icons8/icons8-box-important-64.png"
import likeMessage from "./assets/icons8/icons8-like-message-64.png"
import openEnvelope from "./assets/icons8/icons8-open-envelope-64.png"
import send from "./assets/icons8/icons8-send-64.png"
import urgentMessage from "./assets/icons8/icons8-urgent-message-64.png"
import arrowMore from "./assets/icons8/icons8-expand-arrow-64.png"

const ICONS = {
    "loading": loading,
    "ok": ok,
    "trash": trash,
    "review": review,
    "give": give,
    "so-so": soSo,
    "thumbs-down": thumbsDown,
    "thumbs-up": thumbsUp,
    "bell": bell,
    "book": read,
    "minus": minus,
    "plus": plus,
    "menu": menu,
    "privacy": privacy,
    "security": security,
    "agreement": agreement,
    "friends": friends,
    "search": search,
    "heart": heart,
    "info": info,
    "book-alt": book,
    "library": library,
    "log-out": logOut,
    "cancel": cancel,
    "bookmark": bookmark,
    "important": important,
    "like-message": likeMessage,
    "open-envelope": openEnvelope,
    "send": send,
    "urgent-message": urgentMessage,
    "arrow-more": arrowMore,
}

export type IconName = keyof typeof ICONS;

interface IconProps {
    name: IconName;
    onClick?: () => void;
    size?: "sm" | "md";
    style?: React.CSSProperties;
}

export const Icon: React.FC<IconProps> = ({ name, onClick, style, size = "md" }) => {
    const width = { sm: "24", md: "32" }[size];
    return <img src={ICONS[name]} alt={name} width={width} onClick={onClick} style={style} />
}

export const ButtonIcon: React.FC<IconProps> = ({ name }) => {
    return <img src={ICONS[name]} alt={name} width="16" />
}