import React, { useCallback, useContext, useState } from "react";
import { useGoogleOauth } from "./use_google_oauth";
import { useLocalState } from "./use_local_state";
import { BookData, FriendData, librarySchema, ReviewData, SpreadsheetData, userSchema, useSheets, WishListEntry } from "./use_sheets";
import { useQuery } from '@tanstack/react-query';
import { getKeys } from "../keys";

type BookCache = { [isbn: string]: BookData };
type FriendsData = { [id: string]: SpreadsheetData };

export const UserDataContext = React.createContext<
    { 
        userData: SpreadsheetData | undefined;
        setUserData: (userData: SpreadsheetData | undefined) => void;
        bookCache: BookCache;
        setBookCache: (cache: BookCache) => void;
        friendsData: FriendsData;
        setFriendsData: (friendsData: FriendsData) => void;
        isAuthed: boolean;
        setIsAuthed: (isAuthed: boolean) => void;
    }
>({
    userData: undefined,
    setUserData: () => {},
    bookCache: {},
    setBookCache: () => {},
    friendsData: {},
    setFriendsData: () => {},
    isAuthed: false,
    setIsAuthed: () => {}
});

interface UserDataProviderProps {
    children: React.ReactNode;
}

export const UserDataProvider: React.FC<UserDataProviderProps> = ({ 
    children 
}) => {
    const [userData, setUserData] = useState<SpreadsheetData | undefined>(undefined);
    const [bookCache, setBookCache] = useState<BookCache>({});
    const [friendsData, setFriendsData] = useState<FriendsData>({});
    const [isAuthed, setIsAuthed] = useState(false);

    return <UserDataContext.Provider value={{
        userData, 
        setUserData,
        bookCache,
        setBookCache,
        friendsData,
        setFriendsData,
        isAuthed,
        setIsAuthed
    }}>
        {children}
    </UserDataContext.Provider>;
}

export function useAppData() {
    const { 
        userData, 
        setUserData, 
        bookCache, 
        setBookCache,
        friendsData,
        setFriendsData,
        isAuthed,
        setIsAuthed
    } = useContext(UserDataContext);

    const { clientId } = getKeys();

    const { getToken, signOut, wait } = useGoogleOauth(
        'https://sheets.googleapis.com/$discovery/rest?version=v4',
        'https://www.googleapis.com/auth/drive.file',
        clientId,
        setIsAuthed
    );

    const {
        createSpreadsheet,
        shareSpreadsheet,
        readSpreadsheet,
        addSpreadsheetRow,
        updateSpreadsheetRow,
        readSpreadsheetAnonymous,
        getAllSheets,
        ensureAuthed,
        upsertSpreadsheetRow,
    } = useSheets(wait, getToken);
    
    const [userId, setUserId] 
      = useLocalState<string | undefined>("user_id", undefined);

    const addBookToCache = (bookData: BookData) => {
        bookCache[bookData.bookId] = bookData;
        setBookCache(bookCache);
    }

    const getBookData = (isbn: string) => {
        return bookCache[isbn];
    }

    const setFriendData = (id: string, friendData: SpreadsheetData) => {
        friendsData[id] = friendData;
        setFriendsData({ ...friendsData });
    }

    const getFriendData = (id: string) => {
        if (userData?.users[0].libraryId === id) return userData;
        return friendsData[id];
    }

    const logOut = () => {
        setUserId(undefined);
        localStorage.removeItem("email");
        signOut();
    }

    const fetchUserData = async (userId: string) => {
        const userData = await readSpreadsheet(userId);
        setUserData(userData);
        userData.books.forEach(addBookToCache);
        userData.friends.forEach(async (friendEntry) => {
            const friendData = await readSpreadsheetAnonymous(friendEntry.libraryId);
            friendData.books.forEach(addBookToCache); 
            setFriendData(friendEntry.libraryId, friendData);
        });
        return userData;
    }

    return {
        fetchUserData,
        userData,
        setUserData,
        createSpreadsheet,
        setUserId,
        userId,
        readSpreadsheet,
        addSpreadsheetRow,
        addBookToCache,
        getBookData,
        shareSpreadsheet,
        readSpreadsheetAnonymous,
        friendsData,
        setFriendsData,
        setFriendData,
        getFriendData,
        ensureAuthed,
        getAllSheets,
        updateSpreadsheetRow,
        upsertSpreadsheetRow,
        isAuthed,
        logOut,
    }
}

export function useUserData() {
    const {
        userId,
        readSpreadsheet,
        setUserData,
        userData,
        addSpreadsheetRow,
        upsertSpreadsheetRow,
        addBookToCache,
        getBookData,
        friendsData,
        getFriendData,
        ensureAuthed,
        updateSpreadsheetRow,
        fetchUserData
    } = useAppData();

    if (userId === undefined) {
        throw new Error("Cannot use UserData if not logged in");
    }

    const { isLoading: isLoadingUserData } = useQuery({
        queryKey: ['library', userId], 
        cacheTime: 0,
        queryFn: () => fetchUserData(userId),
    });

    const user = userData && userData.users[0];

    const addBookToLibrary = useCallback(async (book: BookData) => {
        if (user === undefined || userData === undefined) {
            throw new Error("No library is loaded.");
        }
        const libraryData = {
            bookId: book.bookId,
            loanedTo: undefined,
            isDeleted: false,
            addedDate: new Date(),
        };
        const tasks = [
            upsertSpreadsheetRow(
                user.libraryId, 
                "library", 
                libraryData, 
                userData.library, 
                librarySchema
            ),
            upsertSpreadsheetRow(
                user.libraryId,
                "books",
                book,
                userData.books,
                librarySchema
            )
        ];
        const inWishlist = userData.wishlist.find(other => other.bookId === book.bookId);
        if (inWishlist) {
            tasks.push(upsertSpreadsheetRow(
                user.libraryId,
                "wishlist",
                { ...inWishlist, isDeleted: true }, 
                userData.wishlist,
                librarySchema
            ));
        }
        const [library, books, wishlist] = await Promise.all(tasks);
        setUserData({ 
            ...userData, 
            books, 
            library, 
            wishlist: wishlist || userData.wishlist 
        });
        addBookToCache(book);
    }, [
        user, 
        upsertSpreadsheetRow, 
        addBookToCache, 
        userData, 
        setUserData, 
    ]);

    const removeBookFromLibrary = useCallback(async (bookId: string) => {
        if (user === undefined || userData === undefined) {
            throw new Error("No library is loaded.");
        }
        const book = userData.library.find((book) => book.bookId === bookId);
        if (book === undefined) {
            throw new Error("Book not in library");
        }
        const newBookData = {
            ...book,
            isDeleted: true,
        };
        const library = await updateSpreadsheetRow(
            user.libraryId, 
            "library", 
            newBookData, 
            userData.library, 
            book.row, 
            librarySchema
        );
        setUserData({ ...userData, library, });
    }, [user, updateSpreadsheetRow, userData, setUserData]);

    const addBookToWishlist = useCallback(async (book: BookData) => {
        if (user === undefined || userData === undefined) {
            throw new Error("No library is loaded.");
        }
        const wishlistData: WishListEntry = {
            bookId: book.bookId,
            isDeleted: false,
            createdDate: new Date(),
        };
        const [wishlist, books] = await Promise.all([
            await upsertSpreadsheetRow(
                user.libraryId, 
                "wishlist", 
                wishlistData, 
                userData.wishlist, 
                librarySchema
            ),
            await upsertSpreadsheetRow(
                user.libraryId,
                "books",
                book,
                userData.books,
                librarySchema
            )
        ]);
        setUserData({ ...userData, books, wishlist });
        addBookToCache(book);
    }, [user, upsertSpreadsheetRow, addBookToCache, userData, setUserData]);

    const removeBookFromWishlist = useCallback(async (bookId: string) => {
        if (user === undefined || userData === undefined) {
            throw new Error("No library is loaded.");
        }
        const book = userData.wishlist.find((book) => book.bookId === bookId);
        if (book === undefined) {
            throw new Error("Book not in library");
        }
        const newBookData = {
            ...book,
            isDeleted: true,
        };
        const wishlist = await updateSpreadsheetRow(
            user.libraryId, 
            "wishlist", 
            newBookData, 
            userData.wishlist, 
            book.row, 
            librarySchema
        );
        setUserData({ ...userData, wishlist, });
    }, [user, updateSpreadsheetRow, userData, setUserData]);

    const isBookInLibrary = (bookId: string) => {
        return userData !== undefined 
            && userData.library.some((book) => book.bookId === bookId && !book.isDeleted);
    }

    const isBookInWishlist = (bookId: string) => {
        return userData !== undefined 
            && userData.wishlist.some((book) => book.bookId === bookId && !book.isDeleted);
    }

    const isMyFriend = (friendLibraryId: string) => {
        return userData?.friends.some((friend) => friend.libraryId === friendLibraryId);
    }

    const addFriend = useCallback(async (id: string) => {
        if (userId === undefined || userData === undefined) {
            throw new Error("No library is loaded.");
        }
        const friendData: FriendData = {
            libraryId: id,
            isDeleted: false,
        };
        const friends = await upsertSpreadsheetRow(
            userId, 
            'friends', 
            friendData, 
            userData.friends,
            userSchema
        );
        setUserData({ ...userData, friends });
    }, [userId, upsertSpreadsheetRow, userData, setUserData]);

    const setReview = useCallback(async (review: ReviewData) => {
        if (user === undefined || userData === undefined) {
            throw new Error("No library is loaded.");
        }
        const book = getBookData(review.bookId);
        const [reviews] = await Promise.all([
            upsertSpreadsheetRow(
                user.libraryId, 
                'reviews', 
                review, 
                userData.reviews,
                librarySchema
            ),
            upsertSpreadsheetRow(
                user.libraryId,
                "books",
                book,
                userData.books,
                librarySchema
            )
        ]);
        setUserData({ ...userData, reviews });
    }, [user, upsertSpreadsheetRow, userData, setUserData, getBookData]);

    const whoWantsIt = (bookId: string) => {
        return Object.keys(friendsData).filter(libraryId => {
            const friendData = getFriendData(libraryId);
            return friendData.wishlist.some(
                book => !book.isDeleted && book.bookId === bookId
            );
        });
    }

    const reviewsForBook = (bookId: string): (ReviewData & { 
        createdBy: string; 
        isMine: boolean 
    })[] => {
        if (userData === undefined || user === undefined) return [];
        return [
            ...userData.reviews
                .filter(r => r.bookId === bookId && !r.isDeleted)
                .map(r => ({ ...r, createdBy: user.libraryId, isMine: true })),
            ...Object.entries(friendsData).flatMap(([libraryId, friendData]) => {
                return friendData.reviews
                    .filter(r => r.bookId === bookId && !r.isDeleted)
                    .map(r => ({ ...r, createdBy: libraryId, isMine: false }));
            })
        ];
    }

    const whoHasIt = (bookId: string) => {
        return Object.keys(friendsData).filter(libraryId => {
            const friendData = getFriendData(libraryId);
            return friendData.library.some(
                book => !book.isDeleted && book.bookId === bookId
            );
        });
    }

    return {
        userData,
        setUserData,
        user,
        userId,
        readSpreadsheet,
        addSpreadsheetRow,
        addBookToLibrary,
        isLoadingUserData,
        addBookToCache,
        getBookData,
        isBookInLibrary,
        addFriend,
        friendsData,
        getFriendData,
        ensureAuthed,
        addBookToWishlist,
        isBookInWishlist,
        removeBookFromLibrary,
        removeBookFromWishlist,
        whoHasIt,
        whoWantsIt,
        isMyFriend,
        setReview,
        reviewsForBook
    }
}