import { DumbLink, Page, PageTitle } from "./PageContent";

export const TermsPage: React.FC = () => {
    return <Page auth={false}>
        <PageTitle>Terms of Service</PageTitle>
        <p>Last updated: November 2, 2022</p>
        <p><b>Ownership of content:</b> Any data users provide to Dewey is stored in Google Sheets. See Google's privacy policies and terms of service for more information. Users may edit or delete information in their spreadsheets at any time (link in Profile page). Any data stored in the spreadsheet is shared with "link sharing" (anyone can view if they have the link), so information entered in the app should be considered publicly accessible.</p>
        <p><b>Right to change the app at any time:</b> Dewey reserves the right to change the app at any time, including (but not limited to) changing the app logo, name, url/domain, or functionality.</p>
        <p><b>Prohibited uses:</b> Users must use Dewey for its intended purposes.</p>
        <ol>
            <li>Dewey is intended to be used for lending and borrowing books among friends. Any use of Dewey as a means of coordination for selling or renting books is a breach of these terms.</li>
            <li>Reviews are intended to help friends decide whether they want to read books their friends have read. Any use of reviews to coordinate meet-ups or spam other users is a breach of these terms. Reviews must not contain offensive, defamatory, mean-spirited, or pornographic language; abuse; bullying; harassment; impersonation; or hate speech. This includes quotations of the book being reviewed or other books. Reviews may not contain copyright infringement or plagiarized text (quotes from books are okay, as long as they are clearly marked as such). Books that are reviewed on Dewey may pertain to sensitive topics, and thus those reviews may contain sensitive subject material. Users must be mindful of this, agreeing to be careful with how they write about such topics, and agreeing that they may encounter reviews of books on those topics. To report a review that breaks these rules, email <DumbLink href="mailto: dewey.lending.library@gmail.com">dewey.lending.library@gmail.com</DumbLink> with a screenshot of the text and the library ID of the user who posted it (which can be found in the URL on that user's Library page).</li>
            <li>Review text is the intellectual property of the user who wrote it.Plagiarizing another user's review (on Dewey or anywhere else) is a breech of these terms.</li>
            <li>Any use of Dewey for illegal activities is prohibited (and illegal...).</li>
        </ol>
        <p><b>Warranty and liability disclaimers:</b> Dewey provides no warranty for app malfunction, lost data, excess Drive storage space consumed, displaying offensive content created by users, etc.. Dewey does not accept liability for the same. Dewey is made with care, but it may have bugs. Sorry.</p>
        <p><b>If you don't like it, stop using it:</b> Users must agree that recourse for app malfunction, lost data, excess Drive storage space consumed, displaying offensive content created by users, etc. is to just stop using the app (and delete their data on Google Sheets if desired). Users may also contact <DumbLink href="mailto: dewey.lending.library@gmail.com">dewey.lending.library@gmail.com</DumbLink> to make suggestions or report bugs, but Dewey does not guarantee that those emails will be replied to or acted upon in any way (though Dewey will make its best effort to do so).</p>
        <p><b>Termination of service:</b> Dewey may cease service (entirely) at any time (but hopefully not). Dewey also maintains the right to terminate service to particular users or IP addresses, especially (but not limited to) misuse of the app or unacceptable book review content.</p>
        <p><b>Intellectual property:</b> All app code, UI, and functionality is intellectual property of Dewey. All book data (ISBNs, titles, cover images, author names, etc.) is public information provided by <DumbLink href="https://openlibrary.org/">openlibrary.org</DumbLink>. Any use of book data outside Dewey must abide by Open Library's <DumbLink href="https://archive.org/about/terms.php">Terms of Service</DumbLink>. Review text is the intellectual property of the user who wrote it.</p>
        <p><b>Bots and automation:</b> The use of bots or other automation with Dewey is prohibited.</p>
        <p><b>Copyright:</b> All Dewey code, UI, and functionality is copyright of Dewey.</p>
        <p><b>Age of users:</b> Because Dewey contains user content in the form of reviews, it is intended to be used by adults only (18+ in the US). Use of Dewey by those under age is a breach of these terms, and Dewey is not liable for sensitive content viewed by such users. Parents must be mindful of their children's (unintended) use of Dewey.</p>
        <p><b>Updates to terms of service:</b> While the spirit of these terms should stay consistent, they may be changed over time (e.g. adding new terms or modifying existing ones). By agreeing to these terms, users also agree to periodically review the terms in case they have changed.</p>
        <p><b>Contact:</b> For questions, comments, or concerns, contact <DumbLink href="mailto: dewey.lending.library@gmail.com">dewey.lending.library@gmail.com</DumbLink>.</p>
    </Page>;
}