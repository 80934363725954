import { BookGrid } from "./BookGrid";
import { useUserData } from "./hooks/use_user_data";
import { Navigate, useParams } from "react-router-dom";
import { EmptyContent, Link, Page, PageTitle } from "./PageContent";
  
export const WishlistPage: React.FC = () => {
    const { 
        userId, 
        userData,
        getBookData,
        getFriendData,
        whoHasIt,
        isBookInLibrary,
    } = useUserData();

    const { libraryId } = useParams();

    if (userId === undefined) {
        return <Navigate to='/setup' />;
    }

    const isUserLibrary = libraryId === undefined;
    const libraryData = isUserLibrary ? userData : getFriendData(libraryId);

    if (!isUserLibrary && libraryData === undefined) {
        return <Navigate to="/library" />
    }


    const nonDeletedBooks = libraryData && libraryData.wishlist.filter(entry => !entry.isDeleted);
  
    return <Page auth={true}>
        <PageTitle>
            {isUserLibrary ? "My" : libraryData?.users[0].name + "'s"} Wishlist
        </PageTitle>
        { nonDeletedBooks && nonDeletedBooks.length === 0 && <EmptyContent>
            <span>Your Wishlist is empty. Find books to add on the <Link to="/search/">Search Page</Link>.</span>
        </EmptyContent>}
        <BookGrid 
            bookIds={nonDeletedBooks ? nonDeletedBooks.map(entry => entry.bookId) : []}
            getBookData={getBookData}
            getBubble={(bookId) => {
                if (isUserLibrary && whoHasIt(bookId).length) return "u_have";
                if (!isUserLibrary && isBookInLibrary(bookId)) return "i_have";
            }}
        />
    </Page>
}