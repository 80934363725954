import { BookData } from "./hooks/use_sheets";
import styled from "styled-components";
import { Link } from "./PageContent";
import { ClickableBookCover } from "./BookCover";

interface BookGridProps {
    bookIds: string[];
    getBookData: (isbn: string) => BookData | undefined;
    getBubble?: (bookId: string) => string | undefined;
}

export const BookGrid: React.FC<BookGridProps> = ({ 
    bookIds,
    getBookData,
    getBubble,
}) => {
    return <BookGridDiv>
        { bookIds.map((bookId) => {
            const bookData = getBookData(bookId);
            
            if (bookData === undefined) {
                return <Link to={`/books/${bookId}`}><ClickableBookCover 
                    key={bookId}
                    coverImageUrl={undefined} 
                    title={"Unknown"}
                /></Link>;
            }

            return <Link to={`/books/${bookId}`}><ClickableBookCover 
                key={bookId}
                coverImageUrl={bookData.coverImageURL} 
                title={bookData.title}
                bubble={getBubble && getBubble(bookId)}
            /></Link>;
        })}
    </BookGridDiv>
}

const BookGridDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 15px;
    justify-content: center;
`;