import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { Header } from "./Header";
import { NoAuthHeader } from "./NoAuthHeader";
import React, { useState } from "react";
import { useAppData } from "./hooks/use_user_data";
import { Icon } from "./Icon";

interface PageProps {
    auth: boolean;
    children: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({ auth, children }) => {
    const { userId } = useAppData();

    const [isMenuOpen, setIsMenuOpen] = useState(false); 

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    
    return <PageDiv>
        { 
            auth 
            ? <Header toggleMenu={toggleMenu} /> 
            : <NoAuthHeader toggleMenu={toggleMenu} /> 
        }
        { isMenuOpen && <Menu>
            <HeaderLink to="/home" onClick={toggleMenu}>
                <Icon name="info" />
                About
            </HeaderLink>
            { (auth || userId) && <>
                <HeaderLink to="/library" onClick={toggleMenu}>
                    <Icon name="library" />
                    Library
                </HeaderLink>
                <HeaderLink to="/wishlist" onClick={toggleMenu}>
                    <Icon name="heart" />
                    Wishlist
                </HeaderLink>
                <HeaderLink to="/search" onClick={toggleMenu}>
                    <Icon name="search" />
                    Search
                </HeaderLink>
                <HeaderLink to="/friends" onClick={toggleMenu}>
                    <Icon name="friends" />
                    Friends
                </HeaderLink>
            </>}
            <HeaderLink to="/terms" onClick={toggleMenu}>
                <Icon name="agreement" />
                Terms of Service
            </HeaderLink>
            <HeaderLink to="/privacy" onClick={toggleMenu}>
                <Icon name="privacy" />
                Privacy Policy
            </HeaderLink>
        </Menu>}
        { !isMenuOpen && <PageContent>
            <ContentInner>
                {children}
            </ContentInner>
            <Footer>
                Icons by <DumbLink href="https://icons8.com/">icons8</DumbLink>
            </Footer>
            <FooterBuffer />
        </PageContent>}
    </PageDiv>;
};

const PageDiv = styled.div`
    background-color: #f6f8fc;
    width: 100%;
    flex-direction: column;
    display: flex;
    min-height: 100vh;
    height: auto;
`;

const Footer = styled.div`
    color: gray;
    padding: 0 15px;
    margin-top: 10px;

    a {
        color: gray;
        text-decoration: underline;
    }
`;

const FooterBuffer = styled.div`
    height: 20px;
`;

const Menu = styled.div`
    margin-top: 90px;
    margin-bottom: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 30px;
    height: 100%;
    margin-left: 20px;
    gap: 20px;
`;

export const PageContent = styled.div`
    margin: 10px;
    margin-top: 60px;
`;

const ContentInner = styled.div`
    padding: 20px;
    background-color: white;
    border-radius: 5px;
`;

export const PageTitle = styled.h1`
    font-size: 20px;
    margin-block-start: 0px;
`;

export const HeaderTitle = styled.h1`
    font-size: 20px;
    margin: 0px;
`;

export const PageSubtitle = styled.h1`
    font-size: 20px;
    margin-block-start: 10px;
`;

const loadingStyles = `
    @keyframes progress-bar-stripes {
        from {
        background-position: 40px 0;
        }
        to {
        background-position: 0 0;
        }
    }
    animation: progress-bar-stripes 2s linear infinite;
    background-image: linear-gradient(-45deg,rgb(156 109 157 / 29%) 25%,transparent 25%,transparent 50%,rgb(156 109 157 / 29%) 50%,rgb(156 109 157 / 29%) 75%,transparent 75%,transparent);
    background-size: 40px 40px;
`;

export const Button = styled.button<{ isLoading?: boolean }>`
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 15px;
    white-space: nowrap;
    cursor: pointer;

    background-color: #f0eff9;
    color: #8d6c9f;
    border: 1px solid #9c6d9d;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &:disabled {
        border: 1px solid #ab97b6;
        background-color: #f0eff9;
        color: #ab97b6;
    }

    ${({ isLoading }) => isLoading ? loadingStyles : ""}
`;

export const ButtonLink = styled(RouterLink)`
    background-color: #f0eff9;
    color: #8d6c9f;
    border: 1px solid #9c6d9d;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 15px;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &:disabled {
        border: 1px solid #ab97b6;
        background-color: #f0eff9;
        color: #ab97b6;
    }
`;

export const LinkButton = styled.button`
    border: none;
    outline: none;
    background-color; none;
    color: #8d6c9f;
    background-color: transparent;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    font-size: 16px;
`;

export const Link = styled(RouterLink)`
    color: #8d6c9f;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 10px;
`;

export const DumbLink = styled.a`
    color: #8d6c9f;
    text-decoration: none;
`;

const HeaderLink = styled(Link)`
    color: #8d6c9f;
    text-decoration: none;

    &:visited {
        color: #8d6c9f;
        text-decoration: none;
    }
`;

export const EmptyContent = styled.div`
    display: flex;
    margin: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
