import styled from "styled-components";

interface ProfilePictureProps {
    pictureURL: string;
    name: string;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({ pictureURL, name }) => {
    return <ProfileImg 
        width={40}
        src={pictureURL} 
        alt={name} 
        referrerPolicy="no-referrer"
    />;
}

const ProfileImg = styled.img`
    border-radius: 50px;
`;