import styled from "styled-components";
import { Icon } from "./Icon";
import { HeaderTitle } from "./PageContent";

interface NoAuthHeaderProps {
    toggleMenu: () => void;
}

export const NoAuthHeader: React.FC<NoAuthHeaderProps> = ({ toggleMenu }) => {
    return <HeaderDiv>
        <HeaderLinks>
            {/* <Icon name="arrow-more" onClick={toggleMenu} /> */}
            <Icon name="library" onClick={toggleMenu} />
            <HeaderTitle>Dewey</HeaderTitle>
        </HeaderLinks>
    </HeaderDiv>
}


const HeaderDiv = styled.div`
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    min-height: 51px;
    height: 51px;
    position: fixed;
    z-index: 1;
    background-image: linear-gradient(#f6f8fc, #f6f8fcd1);
    backdrop-filter: blur(2px);
`;

const HeaderLinks = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;