import { useUserData } from "./hooks/use_user_data";
import QRCodeSVG from "qrcode.react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { useMutation } from '@tanstack/react-query'
import { PageTitle, ButtonLink, DumbLink, Button, Page } from "./PageContent";
import styled from "styled-components";
import { ButtonIcon } from "./Icon";

export const ProfilePage: React.FC = () => {
    const { 
        user,
        userId, 
        addFriend,
        isMyFriend,
    } = useUserData();
    const navigate = useNavigate();

    const { userName: friendName, libraryId: friendLibraryId } = useParams();

    const subscribe = useMutation(() => addFriend(friendLibraryId || "none"));

    if (user === undefined) {
        return <Page auth={true}>
            <PageTitle>Profile</PageTitle>
            Loading...
        </Page>;
    }

    if (friendLibraryId === undefined || friendName === undefined) {
        return <Navigate to={`/profile/${user.libraryId}/${user.name}`} />;
    }

    if (userId === undefined) {
        return <Navigate to='/setup' />;
    }

    if (isMyFriend(friendLibraryId)) {
        // TODO why doesn't this send to the right page?
        return <Navigate to={`/library/${friendLibraryId}`} state={{
            libraryId: friendLibraryId
        }}/>;
    }

    if (friendLibraryId === undefined || friendLibraryId === user.libraryId) {
        return <Page auth={true}>
            <PageTitle>Profile</PageTitle>
            <Row>
                {user.name}
                <ButtonLink to="/exit">
                    <ButtonIcon name="log-out"/>Log Out
                </ButtonLink>
            </Row>
            <CenterRow><QRCodeSVG 
                value={`https://dewey.cglyph.xyz/profile/${user.libraryId}/${user.name}`} 
            /></CenterRow>
            <p>To share your library with a friend, share this page or show them this QR code.</p>
            <p><DumbLink 
                href={`https://docs.google.com/spreadsheets/d/${userId}/`}
            >View your data in Google Drive</DumbLink></p>
            <p><DumbLink 
                href={`https://docs.google.com/spreadsheets/d/${user.libraryId}/`}
            >View your library in Google Drive</DumbLink></p>
        </Page>;
    } else {
        return <Page auth={true}>
            { (friendLibraryId && friendName) ? <>
                <p>{friendName} has invited you to subscribe to their library!</p>
                <Button
                    onClick={async () => {
                        await subscribe.mutateAsync();
                        // TODO why doesn't this send to the right page?
                        navigate(`/library/${friendLibraryId}`);
                    }}
                    isLoading={subscribe.isLoading}
                    disabled={subscribe.isLoading}
                >Subscribe</Button>
            </> : <>
                Invalid friend link.
            </>}
        </Page>
    }
}

const Row = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
`

const CenterRow = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
`