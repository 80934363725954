import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppData } from "./hooks/use_user_data";

export const RequireLibrary: React.FC = () => {
    const { 
        userId,
        isAuthed,
    } = useAppData();
    let location = useLocation();

    if (userId === undefined && !isAuthed) {
        return <Navigate to='/setup' />;
    }

    if (!isAuthed) {
        return <Navigate to={`/auth?next=${location.pathname}`} />;
    }
  
    return <Outlet />;
}