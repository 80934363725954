import { useRef } from "react";
import styled from "styled-components";

interface TextInputProps {
    value: string;
    setValue: (value: string) => void;
    placeholder?: string;
}

export const TextAreaInput: React.FC<TextInputProps> = ({ 
    value,
    setValue,
    placeholder
}) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    return <InputEl 
        ref={inputRef} 
        value={value}
        placeholder={placeholder}
        rows={6}
        onChange={(event) => { setValue(event.target.value) }}
    />
}

const InputEl = styled.textarea`
    border: 1px solid #9c6d9d;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 15px;
    font-family: inherit;
    resize: none;
    width: calc(100% - 20px);

    outline: none;

    &:active {
        outline: none;
    }
`;