import { useUserData } from "./hooks/use_user_data";
import { Navigate, Link } from "react-router-dom";
import { ProfilePicture } from "./ProfilePicture";
import { Page, PageTitle } from "./PageContent";
import styled from "styled-components";
  
export const FriendsPage: React.FC = () => {
    const { 
        userId, 
        userData,
        getFriendData,
    } = useUserData();

    if (userId === undefined) {
        return <Navigate to='/setup' />;
    }

    return <Page auth={true}>
        <PageTitle>Friends</PageTitle>
        {
            userData?.friends.map((friend) => {
                const friendData = getFriendData(friend.libraryId);
                const user = friendData?.users[0];
                return user ? <FriendLine>
                    <VerticalCenter>
                        <ProfilePicture 
                            pictureURL={user.pictureURL} 
                            name={user.name} 
                        />
                        {user.name}
                    </VerticalCenter> 
                    <Link to={`/library/${user.libraryId}`}>View Library</Link>
                </FriendLine> : <></>;
            })
        }
    </Page>
}

const FriendLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`;

const VerticalCenter = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`