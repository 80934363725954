import { useState } from "react";
import { TextInput } from "./TextInput";
import { useMutation } from '@tanstack/react-query'
import { useAppData } from "./hooks/use_user_data";
import { Navigate, useSearchParams, useNavigate } from "react-router-dom";
import { Button, Link, LinkButton, PageContent, PageTitle } from "./PageContent";
import styled from "styled-components";
import { ButtonIcon } from "./Icon";

export const SetupLibraryPage: React.FC = () => {
    const { 
        createSpreadsheet, 
        setUserId,
        userId,
        getAllSheets,
        fetchUserData
    } = useAppData();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [loadExistingLibrary, setLoadExistingLibrary] = useState(false);
    const [libraryId, setLibraryId] = useState("");
    const createLibrary = useMutation(createSpreadsheet);
    const checkForLibrary = useMutation(getAllSheets);
    const loadManual = useMutation(async (libraryId: string) => {
        await fetchUserData(libraryId);
        setUserId(libraryId);
    });

    if (userId) {
        return <Navigate to={searchParams.get("next") || `/library/${userId}`} />;
    }

    return <FullPageContent>
        <Title>Welcome to Dewey</Title>
        <Subtitle>The distributed lending library</Subtitle>
        
        { 
            loadExistingLibrary 
            ? <SetupOptions>
                <Button onClick={() => setLoadExistingLibrary(false)}>
                    Back
                </Button>
                <TextInput 
                    value={libraryId} 
                    setValue={setLibraryId} 
                    placeholder="Library ID"
                />
                <Button 
                    onClick={() => loadManual.mutateAsync(libraryId)}
                    disabled={libraryId.length !== 44 || loadManual.isLoading}
                    isLoading={loadManual.isLoading}
                >
                    Load Library
                </Button>
            </SetupOptions> 
            : <SetupOptions>
                <Button
                    onClick={async () => {
                        const libraryId = await createLibrary.mutateAsync();
                        setUserId(libraryId);
                        await fetchUserData(libraryId);
                        navigate(searchParams.get("next") || "/library");
                    }}
                    isLoading={createLibrary.isLoading}
                    disabled={createLibrary.isLoading}
                >
                    <ButtonIcon name="plus" />
                    Create New Library
                </Button>
        
                <Button 
                    onClick={async () => {
                        const libraries = await checkForLibrary.mutateAsync();
                        const library = libraries[0];
                        if (library === undefined) {
                            setLoadExistingLibrary(true);
                        } else {
                            setUserId(library);
                            await fetchUserData(library);
                        }
                    }}
                    isLoading={checkForLibrary.isLoading}
                    disabled={checkForLibrary.isLoading}
                >
                    <ButtonIcon name="library" />
                    Use Existing Library
                </Button>
                <LinkButton onClick={() => setLoadExistingLibrary(true)}>
                    Or, use manual entry
                </LinkButton>
                <Info>
                    Your library will be stored in a Google Sheet created by Dewey on your account, and you can access it at any time. Dewey is not affiliated with Google. For more info, see the <Link to="/privacy">Privacy Policy</Link>.
                </Info>
                <Info>
                    By accessing or using Dewey, you agree to the <Link to="/terms">Terms of Service</Link>.
                </Info>
            </SetupOptions>
        }
    </FullPageContent>
}

const FullPageContent = styled(PageContent)`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Title = styled(PageTitle)`
    font-size: 30px;
    margin-top: -10px;
    margin-bottom: 20px;
`;

const Subtitle = styled.div`
    font-size: 20px;
    margin-top: -10px;
    margin-bottom: 20px;
`;

const SetupOptions = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 300px;
`;

const Info = styled.p`
    color: rgb(26, 56, 32);
    text-align: center;
    margin-bottom: 0;
`;