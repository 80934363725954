export function getKeys() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return {
            clientId: "170925395983-ilvfgeg656lii5d5ldi7bacv61ft7ntc.apps.googleusercontent.com",
            apiKey: "AIzaSyD5XnfZTKnQoB0macIcYViisspaZdIKKmk"
        }
    } else {
        return {
            clientId: "328328091541-dhf9pdukh01ma2euuqrbb11fsk1g6gq9.apps.googleusercontent.com",
            apiKey: "AIzaSyAzxuqHfJmbKODjzFhi9YZvyujbJU8_Wfo"
        }
    }
}