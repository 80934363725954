import { useState } from "react";
import { TextInput } from "./TextInput";
import { useQuery } from '@tanstack/react-query';
import styled from "styled-components";
import { BookData } from "./hooks/use_sheets";
import { useUserData } from "./hooks/use_user_data";
import { BookCover } from "./BookCover";
import { Button, PageTitle, Link, Page } from "./PageContent";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ButtonIcon } from "./Icon";

export const SearchPage: React.FC = () => {
    const {
        addBookToLibrary,
        getBookData,
        addBookToCache,
        isBookInLibrary
    } = useUserData();
    const navigate = useNavigate();

    const { term } = useParams();

    const [searchTerm, setSearchTerm] = useState(term || "");

    const { data, isLoading } = useQuery(['search', term || ""], () => searchBooks(term));

    return <Page auth={true}>
        <PageTitle>Search Books</PageTitle>
        <SearchRow>
            <TextInput value={searchTerm} setValue={setSearchTerm} isSearch={true} />
            <Button
                type="submit"
                formAction=""
                onClick={(event) => {
                    event.preventDefault();
                    navigate(`/search/${searchTerm}`);
                    let el = document.querySelector(':focus');
                    if (el) (el as unknown as { blur: () => void }).blur();
                }}
                disabled={isLoading}
                isLoading={isLoading && !!term}
            ><ButtonIcon name="search" />Search</Button>
        </SearchRow>
        { term && <SearchResults 
            data={data} 
            addBookToLibrary={addBookToLibrary} 
            getBookData={getBookData} 
            addBookToCache={addBookToCache}
            isBookInLibrary={isBookInLibrary}
        /> }
    </Page>
}

const SearchRow = styled.form`
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
`;

interface SearchResultsProps {
    data: Doc[] | undefined;
    addBookToLibrary: (book: BookData) => Promise<void>;
    getBookData: (isbn: string) => BookData | undefined;
    addBookToCache: (book: BookData) => void;
    isBookInLibrary: (isbn: string) => boolean;
}

async function searchBooks(searchTerm: string | undefined) {
    if (searchTerm === undefined) return [];
    const params = new URLSearchParams({
        q: searchTerm,
        fields: "key,title,author_name,publish_date,cover_i,isbn,type"
    }).toString()
    const response = await fetch(`https://openlibrary.org/search.json?${params}`);
    const result = await response.json();
    return result.docs as Doc[];
}

interface Doc {
    key: string;
    type: string;
    title: string;
    author_name: string[];
    publish_date: string[];
    cover_i: number;
    isbn: string[];
}

function findFirstPublishDate(dates: string[]) {
    function toDate(dateString: string) {
        const format = dateString.length === 4 
            ? "YYYY" 
            : dateString.length === 7 
            ? "YYYY-MM" 
            : "MMM DD, YYYY";
        return moment(dateString, format).unix();
    }
    return dates.sort((a, b) => toDate(a) - toDate(b))[0];
}

function hasCover(docA: Doc, docB: Doc) {
    if (docA.cover_i && docB.cover_i) {
        return 0;
    } else if (docA.cover_i) {
        return -1;
    } else {
        return 1;
    }
}

export const SearchResults: React.FC<SearchResultsProps> = ({
    data,
    addBookToLibrary,
    getBookData,
    addBookToCache,
    isBookInLibrary
}) => {
    return <SearchResultsDiv>
        { data?.filter(doc => doc.type === "work").sort(hasCover).map(doc => {
            const bookId = doc.key.substring("/works/".length);
            const book: BookData = {
                bookId,
                title: doc.title,
                coverImageURL: doc.cover_i 
                    ? `https://covers.openlibrary.org/b/id/${doc.cover_i}-M.jpg`
                    : undefined,
                authors: doc.author_name
                    ? doc.author_name.join(", ")
                    : "",
                publishDate: doc.publish_date
                    ? findFirstPublishDate(doc.publish_date)
                    : "?",
            }
            return <SearchResult
                key={bookId} 
                book={book} 
                addBookToLibrary={addBookToLibrary}
                getBookData={getBookData}
                addBookToCache={addBookToCache}
                isInLibrary={isBookInLibrary(bookId)}
            />;
        })}
    </SearchResultsDiv>
}

const SearchResultsDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

interface SearchResultProps {
    book: BookData;
    addBookToLibrary: (book: BookData) => Promise<void>;
    getBookData: (isbn: string) => BookData | undefined;
    addBookToCache: (book: BookData) => void;
    isInLibrary: boolean;
}

export const SearchResult: React.FC<SearchResultProps> = ({
    book,
    addBookToCache,
    isInLibrary
}) => {
    return <SearchResultRow>
        <FlexBox>
            <Link 
                to={`/books/${book.bookId}`}
                onClick={() => addBookToCache(book)}
            >
                <BookCover title={book.title} coverImageUrl={book.coverImageURL} />
            </Link>
            <SearchResultInfo>
                <SearchResultTitle>{ book.title }</SearchResultTitle>
                <SearchResultAuthor>by { book.authors }</SearchResultAuthor>
                <SearchResultPublished>First published { book.publishDate }</SearchResultPublished>
            </SearchResultInfo>
        </FlexBox>
    </SearchResultRow>
}

const SearchResultRow = styled.div`
    width: 100%;
    display: flex;
    padding: 10px 0px;
    justify-content: space-between;
`;

const FlexBox = styled.div`
    display: flex;
    width: 100%;
`;

const SearchResultInfo = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const SearchResultTitle = styled.div`
    font-weight: bold;
`;

const SearchResultAuthor = styled.div`
`;

const SearchResultPublished = styled.div`
    font-size: 12px;
    color: gray;
`
