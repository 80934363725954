import { TextAreaInput } from "./TextAreaInput";
import styled from "styled-components";
import { Icon, IconName } from "./Icon";

export interface ReviewFormData {
    text?: string;
    score?: number | undefined;
}

interface ReviewFormProps {
    review: ReviewFormData;
    setReview: (review: ReviewFormData) => void;
}

export const REVIEW_SCORES = [
    "Bad",
    "Mediocre",
    "Okay",
    "Good",
    "Great"
];

export const REVIEW_SCORE_ICONS: { icon: IconName, color: string }[] = [
    { icon: "trash", color: "rgb(134 167 65)" },
    { icon: "thumbs-down", color: "rgb(219 118 67)" },
    { icon: "ok", color: "rgb(222 181 44)" },
    { icon: "thumbs-up", color: "#4e8bd5" },
    { icon: "heart", color: "rgb(219, 73, 92)" },
];

export const ReviewForm: React.FC<ReviewFormProps> = ({ review, setReview }) => {
    return <ReviewFormDiv>
        <Buttons>{ [1, 2, 3, 4, 5].map(score => {
            const icon = REVIEW_SCORE_ICONS[score - 1];
            return <ReviewIcon 
                key={score}
                name={icon.icon}
                style={{ 
                    filter: score === review.score ? "none" : "grayscale(1) opacity(50%)",
                }}
                onClick={() => setReview({ ...review, score })}
            />
        })}</Buttons>
        <TextAreaInput 
            value={review.text || ""} 
            setValue={(text) => setReview({ ...review, text })}
            placeholder={"What did you think?"} 
        />
    </ReviewFormDiv>
}

const ReviewFormDiv = styled.div`
    margin-top: 20px;
`;

export const ReviewIcon = styled(Icon)`
    padding: 8px;
    filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .3));
    color: white;
    border-radius: 100%;
    width: 20px;
    height: 20px;
`;

const Buttons = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
`;