import { getKeys } from "../keys";

type GetToken = (error: any) => Promise<void>;

function addSheetRequest(title: string, sheetId: number) {
    return sheetId === 0 ? {
        updateSheetProperties: {
            properties: {
                title,
                sheetId
            },
            fields: "title"
        },
    } : {
        addSheet: {
            properties: {
                title,
                sheetId
            },
        },
    };
}

function columnName(columnIndex: number) {
    return "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(columnIndex);
}

function addHeaderRequest(sheetName: string, columns: { name: string, type: string }[]) {
    return {
        range: `${sheetName}!A1:${columnName(columns.length - 1)}1`,
        majorDimension: "ROWS",
        values: [columns.map(column => column.name)]
    }
}

export type Schema = { 
    [tableName: string]: { 
        fields: { name: string, type: string }[], 
        primaryKey: string 
    }
};

export const librarySchema: Schema = {
    "library": {
        fields: [
            { name: "bookId", type: "string" }, 
            { name: "loanedTo", type: "string" }, 
            { name: "isDeleted", type: "boolean" }, 
            { name: "addedDate", type: "date" }
        ],
        primaryKey: "bookId"
    }, 
    "books": {
        fields: [
            { name: "bookId", type: "string" }, 
            { name: "title", type: "string" }, 
            { name: "authors", type: "string" }, 
            { name: "publishDate", type: "string" }, 
            { name: "coverImageURL", type: "string" }
        ],
        primaryKey: "bookId",
    }, 
    "wishlist": {
        fields: [
            { name: "bookId", type: "string" } ,
            { name: "isDeleted", type: "boolean" },
            { name: "createdDate", type: "date" }
        ],
        primaryKey: "bookId"
    }, 
    "reviews": {
        fields: [
            { name: "bookId", type: "string" }, 
            { name: "score", type: "number" }, 
            { name: "text", type: "string" }, 
            { name: "createdDate", type: "date" },
            { name: "isDeleted", type: "boolean" }
        ],
        primaryKey: "bookId"
    },
    "users": {
        fields: [
            { name: "name", type: "string" }, 
            { name: "pictureURL", type: "string" }, 
            { name: "libraryId", type: "string" }
        ],
        primaryKey: "libraryId"
    },
}

export const userSchema: Schema = {
    "friends": {
        fields: [
            { name: "libraryId", type: "string" }, 
            { name: "isDeleted", type: "boolean" },
            { name: "name", type: "string" }, 
            { name: "pictureURL", type: "string" }, 
        ],
        primaryKey: "libraryId"
    },
    "users": {
        fields: [
            { name: "userId", type: "string" }, 
            { name: "email", type: "string" }, 
            { name: "name", type: "string" }, 
            { name: "pictureURL", type: "string" }, 
            { name: "libraryId", type: "string" }
        ],
        primaryKey: "userId"
    },
}

export type UserData = { 
    userId: string; 
    email: string; 
    name: string;
    pictureURL: string;
    libraryId: string;
};

export type WithRowNumber = {
    row: number;
}

export type LibraryData = { 
    bookId: string; 
    loanedTo: string | undefined; 
    isDeleted: boolean;
    addedDate: Date;
};

export type BookData = { 
    bookId: string; 
    title: string; 
    authors: string;
    publishDate: string;
    coverImageURL?: string;
};

export type WishListEntry = { 
    bookId: string; 
    isDeleted: boolean; 
    createdDate: Date;
};

export type FriendData = { 
    libraryId: string; 
    isDeleted: boolean; 
};

export type ReviewData = {
    bookId: string;
    score?: number;
    text?: string | undefined;
    createdDate: Date;
    isDeleted: boolean;
};

export type SpreadsheetData = {
    users: (UserData & WithRowNumber)[],
    library: (LibraryData & WithRowNumber)[],
    books: (BookData & WithRowNumber)[],
    wishlist: (WishListEntry & WithRowNumber)[],
    friends: (FriendData & WithRowNumber)[],
    reviews: (ReviewData & WithRowNumber)[]
}

// From https://stackoverflow.com/a/16233621
function dateFromSerialNumber(serial: number) {
    var utc_days = Math.floor(serial - 25568);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000);
 
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
 
    var total_seconds = Math.floor(86400 * fractional_day);
 
    var seconds = total_seconds % 60;
 
    total_seconds -= seconds;
 
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
 
    return new Date(
        date_info.getFullYear(), 
        date_info.getMonth(), 
        date_info.getDate(), 
        hours, 
        minutes, 
        seconds
    );
 }

function getStringValue(column: any) {
    const value = column.effectiveValue;
    if (value === undefined) {
        return undefined;
    } else if ("stringValue" in value) {
        return value.stringValue;
    } else if ("numberValue" in value) {
        const type = column.effectiveFormat?.numberFormat?.type;
        if (type === "DATE" || type === "DATE_TIME") {
            return dateFromSerialNumber(value.numberValue).toString();
        }
        return `${value.numberValue}`;
    } else if ("boolValue" in value) {
        return `${value.boolValue}`;
    } else if ("dateValue" in value) {
        return `${value.dateValue}`;
    } else {
        return undefined;
    }
}

function convertToString(value: any) {
    if (value === undefined || value === null) {
        return undefined;
    } else {
        return value.toString();
    }
}

function convertTo(value: string, type: string) {
    if (type === "string") {
        return value;
    } else if (type === "boolean") {
        return value === "true";
    } else if (type === "date") {
        return new Date(value);
    } else if (type === "number") {
        return value === undefined ? value : parseFloat(value);
    }
}

type DataValue = string | number | boolean | Date | undefined;

export function useSheets(wait: () => Promise<void>, getToken: GetToken) {
    const sheets = async () => { await wait(); return (gapi.client as any).sheets; }
    const drive = async () => { await wait(); return (gapi.client as any).drive; }
    const oauth2 = async () => { await wait(); return (gapi.client as any).oauth2; }

    function doRetry(method: any, ...args: any[]) {
        return new Promise((resolve, reject) => {
            method(...args).then(resolve).catch((e: any) => getToken(e).then(
                () => {
                    console.log("User authenticated.");
                    return method(...args).then(resolve).catch(reject)
                }
            ));
        });
    }

    const shareSpreadsheet = async (userEmail: string, fileId: string) => {
        const result: any = await doRetry((await drive()).permissions.create, {
            emailMessage: `A Bibnet library has been shared with you! To accept, go to https://bibnet.club/${fileId}`,
            sendNotificationEmail: true,
            fileId,
            resource: {
                role: "reader",
                type: "user",
                emailAddress: userEmail,
            }
        });
        return result;
    }

    const readSpreadsheetAnonymous = async (fileId: string) => {
        const apiKey = "AIzaSyD5XnfZTKnQoB0macIcYViisspaZdIKKmk";
        const response = await fetch(`https://content-sheets.googleapis.com/v4/spreadsheets/${fileId}?includeGridData=true&key=${apiKey}`);
        const result = await response.json();
        return processSpreadsheetData(result, librarySchema);
    }

    const processSpreadsheetData = (result: any, schema: Schema) => {
        const data: { [columnName: string]: Record<string, DataValue>[] } = {};
        for (const sheet of result.sheets) {
            const tableName = sheet.properties.title;
            if (!Object.keys(schema).includes(tableName)) continue;
            const rows = sheet.data[0].rowData.slice(1);
            const headers = sheet.data[0].rowData[0].values.map(getStringValue);
            const dataRows: Record<string, DataValue>[] = [];
            rows.forEach((row: any, index: number) => {
                const dataRow: Record<string, DataValue> = { row: index };
                row.values.forEach((column: any, index: number) => {
                    const columnName = headers[index];
                    const field = schema[tableName].fields
                        .find(field => field.name === columnName);
                    if (field === undefined) return;
                    dataRow[columnName] = convertTo(getStringValue(column), field.type);
                });
                dataRows.push(dataRow);
            });
            data[tableName] = dataRows;
        }
        return data as unknown as SpreadsheetData;
    }

    const readSpreadsheet = async (fileId: string, noAuth = false) => {
        console.log("Getting ", fileId);
        const { apiKey } = getKeys();
        const privateResult: any = await doRetry((await sheets()).spreadsheets.get, {
            spreadsheetId: fileId,
            includeGridData: true,
            key: apiKey
        });
        const privateData = processSpreadsheetData(privateResult.result, userSchema);

        const publicDataId = privateData.users[0].libraryId;
        const publicResult: any = await doRetry((await sheets()).spreadsheets.get, {
            spreadsheetId: publicDataId,
            includeGridData: true,
            key: apiKey
        });
        const publicData = processSpreadsheetData(publicResult.result, librarySchema);

        return { ...privateData, ...publicData };
    }

    const addSpreadsheetRow = async (
        fileId: string, 
        tableName: string, 
        data: Record<string, any>,
        entries: any[],
        schema: Schema
    ) => {
        await doRetry((await sheets()).spreadsheets.batchUpdate, {
            spreadsheetId: fileId,
            resource: {
                requests: {
                    appendCells: {
                        sheetId: Object.keys(schema).indexOf(tableName),
                        rows: [
                            {
                                values: schema[tableName].fields.map(
                                    (field) => ({
                                        userEnteredValue: {
                                            stringValue: convertToString(data[field.name])
                                        }
                                    })
                                ),
                            }
                        ],
                        fields: "*"
                    },
                }
            },
        });
        return [...entries, { ...data, row: entries.length }];
    }

    const ensureAuthed = async () => {
        const userInfo = await doRetry((await oauth2()).userinfo.get) as any;
        localStorage.setItem("email", userInfo.result.email);
    }

    const getAllSheets = async (): Promise<string[]> => {
        await ensureAuthed();
        const result = await doRetry((await drive()).files.list, {
            q: "mimeType='application/vnd.google-apps.spreadsheet' and name contains 'Dewey Data'",
            orderBy: "modifiedByMeTime desc,modifiedTime desc"
        }) as any;
        return result.result.files.map((file: any) => file.id);
    };

    const createSpreadsheet = async (): Promise<string> => {
        const userInfo = await doRetry((await oauth2()).userinfo.get) as any;
        const name = userInfo.result.name;
        const email = userInfo.result.email;
        localStorage.setItem("email", email);
        const pictureURL = userInfo.result.picture;
        const publicResult: any = await doRetry((await sheets()).spreadsheets.create, {
            properties: { title: `${name}'s Dewey Library` },
        });
        const privateResult: any = await doRetry((await sheets()).spreadsheets.create, {
            properties: { title: `${name}'s Dewey Data` },
        });
        const userId = privateResult.result.spreadsheetId;
        const libraryId = publicResult.result.spreadsheetId;
        await doRetry((await sheets()).spreadsheets.batchUpdate, {
            spreadsheetId: userId,
            resource: {
                requests: Object.keys(userSchema).map(addSheetRequest),
            },
        });
        await doRetry((await sheets()).spreadsheets.batchUpdate, {
            spreadsheetId: libraryId,
            resource: {
                requests: Object.keys(librarySchema).map(addSheetRequest),
            },
        });
        await doRetry((await sheets()).spreadsheets.values.batchUpdate, {
            spreadsheetId: userId,
            resource: {
                data: Object.entries(userSchema).map(
                    ([sheetName, table]) => addHeaderRequest(sheetName, table.fields)
                ),
                valueInputOption: "RAW"
            },
        });
        await doRetry((await sheets()).spreadsheets.values.batchUpdate, {
            spreadsheetId: libraryId,
            resource: {
                data: Object.entries(librarySchema).map(
                    ([sheetName, table]) => addHeaderRequest(sheetName, table.fields)
                ),
                valueInputOption: "RAW"
            },
        });
        await addSpreadsheetRow(userId, "users", {
            userId,
            name,
            pictureURL,
            libraryId,
        }, [], userSchema);
        await addSpreadsheetRow(libraryId, "users", {
            libraryId,
            name,
            pictureURL,
        }, [], librarySchema);
        await doRetry((await drive()).permissions.create, {
            sendNotificationEmail: false,
            fileId: libraryId,
            resource: {
                role: "reader",
                type: "anyone",
            }
        });
        return userId;
    }

    const updateSpreadsheetRow = async (
        fileId: string, 
        tableName: string, 
        data: Record<string, any>,
        entries: any[],
        row: number,
        schema: Schema
    ) => {
        const table = schema[tableName];
        const range = `${tableName}!A${row + 2}:${columnName(table.fields.length - 1)}${row + 2}`;
        await doRetry((await sheets()).spreadsheets.values.update, {
            spreadsheetId: fileId,
            range,
            valueInputOption: "RAW",
            resource: {
                range,
                majorDimension: "ROWS",
                values: [table.fields.map(
                    (field) => convertToString(data[field.name])
                )],
            },
        });
        return entries.map(old => old[table.primaryKey] === data[table.primaryKey]
            ? { ...data, row } 
            : old
        );
    }

    const upsertSpreadsheetRow = async (
        fileId: string,
        tableName: string,
        data: Record<string, any>,
        entries: any[],
        schema: Schema
    ) => {
        const table = schema[tableName];
        const existing = entries
            .find((row: any) => row[table.primaryKey] === data[table.primaryKey]);
        if (existing !== undefined) {
            return updateSpreadsheetRow(fileId, tableName, data, entries, existing.row, schema);
        } else {
            return addSpreadsheetRow(fileId, tableName, data, entries, schema);
        }
    }

    return {
        createSpreadsheet,
        shareSpreadsheet,
        readSpreadsheet,
        addSpreadsheetRow,
        updateSpreadsheetRow,
        upsertSpreadsheetRow,
        readSpreadsheetAnonymous,
        ensureAuthed,
        getAllSheets
    }
}