import { useAppData } from "./hooks/use_user_data";
import { Button, Link, Page,  } from "./PageContent";
import { Navigate, useSearchParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "@tanstack/react-query";

export const AuthPage: React.FC = () => {
    const { 
        ensureAuthed,
        userId,
        isAuthed,
        fetchUserData
    } = useAppData();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const doAuth = useMutation(async () => {
        if (userId) {
            await ensureAuthed();
            await fetchUserData(userId);
        } else {
            navigate("/setup");
        }
    });

    if (isAuthed && !doAuth.isLoading && !doAuth.error) {
        return <Navigate to={searchParams.get("next") || "/library"} />;
    }

    if (!userId) {
        return <Navigate to="/setup" />;
    }

    return <Page auth={false}>
        It looks like you have a library open...
        <Center><Button 
            onClick={() => doAuth.mutateAsync()}
            isLoading={doAuth.isLoading}
            disabled={doAuth.isLoading}
        >Continue</Button></Center>
        Or, <Link to="/exit">log out</Link>.
    </Page>
}

const Center = styled.div`
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;