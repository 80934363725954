import { BookGrid } from "./BookGrid";
import { useUserData } from "./hooks/use_user_data";
import { Navigate, useParams } from "react-router-dom";
import { Page, PageTitle, PageSubtitle, Link, EmptyContent } from "./PageContent";
  
export const LibraryPage: React.FC = () => {
    const { 
        userId, 
        userData,
        getBookData,
        getFriendData,
        whoWantsIt,
        isBookInWishlist,
        isBookInLibrary
    } = useUserData();

    const { libraryId } = useParams();

    if (userId === undefined) {
        return <Navigate to='/setup' />;
    }

    const isUserLibrary = libraryId === undefined;
    const libraryData = isUserLibrary ? userData : getFriendData(libraryId);

    if (!isUserLibrary && libraryData === undefined) {
        return <Navigate to="/library" />
    }

    const readAndReviewed = (libraryData?.reviews || [])
        .filter(review => !isBookInLibrary(review.bookId))
        .map(review => review.bookId);

    const nonDeletedBooks = libraryData && libraryData.library.filter(entry => !entry.isDeleted);
  
    return <Page auth={true}>
        <PageTitle>
            {isUserLibrary ? "My" : libraryData?.users[0].name + "'s"} Library
        </PageTitle>
        { nonDeletedBooks && nonDeletedBooks.length === 0 && <EmptyContent>
            <span>Your Library is empty. Find books to add on the <Link to="/search/">Search Page</Link>.</span>
        </EmptyContent>}
        <BookGrid 
            bookIds={nonDeletedBooks ? nonDeletedBooks.map(entry => entry.bookId) : []}
            getBookData={getBookData}
            getBubble={(bookId) => {
                if (isUserLibrary && whoWantsIt(bookId).length) return "u_want";
                if (!isUserLibrary && isBookInWishlist(bookId)) return "i_want";
            }}
        />
        {readAndReviewed.length > 0 && <>
            <PageSubtitle>
                Read & Reviewed
            </PageSubtitle>
            <BookGrid 
                bookIds={readAndReviewed}
                getBookData={getBookData}
                getBubble={(bookId) => {
                    if (isUserLibrary && whoWantsIt(bookId).length) return "u_want";
                    if (!isUserLibrary && isBookInWishlist(bookId)) return "i_want";
                }}
            />
        </>}
    </Page>
}