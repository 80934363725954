import styled from "styled-components";
import bookGloss from "./assets/book-gloss.png";
import { Icon } from "./Icon";

interface BookCoverProps {
    coverImageUrl: string | undefined;
    title: string;
    bubble?: string;
}

export const BookCover: React.FC<BookCoverProps> = ({ title, coverImageUrl, bubble }) => {
    return <Wrapper>{coverImageUrl ? <BookImage 
        width={84}
        height={130}
        src={coverImageUrl} 
        alt={title}
    /> : <PlaceholderCover>
        { title }
    </PlaceholderCover>}
    <Gloss 
        src={bookGloss} 
        alt="gloss" 
        width={84}
        height={130}
    />{bubble && <Bubble kind={bubble}/>}</Wrapper>;
}

export const Bubble: React.FC<{ kind: string}> = ({ kind }) => {
    return <BubbleDiv>
        <Icon name={"urgent-message"} size="sm" />
    </BubbleDiv>;
}

const Gloss = styled.img`
    position: absolute;
    top: 0;
    left: 0;
`;

const BubbleDiv = styled.div`
    img:first-child {
        position: absolute;
        top: -10px;
        right: -7px;
        width: 36px;
        transform: scaleX(-1);
    }

    img {
        filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .3));
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const PlaceholderCover = styled.div`
    border-radius: 1px;
    background-repeat: round;
    color: rgb(49, 65, 50);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    padding-top: 20px;
    text-overflow: ellipsis;
    display: block;

    width: 64px;
    max-width: 64px;
    min-width: 64px;
    height: 100px;
    font-size: 13px;
    background-color: #437060;
    color: #e5e3e3;
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    border-radius: 2px;
    height: 130px;
    max-height: 130px;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.28);
    &:after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }
`

const BookImage = styled.img`
    border-radius: 1px;
`;

export const ClickableBookCover = styled(BookCover)`
    cursor: pointer;

    &:hover {
        opacity: 90%;
    }
`;