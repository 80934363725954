import { useUserData } from "./hooks/use_user_data";
import { Link } from "react-router-dom";
import { ProfilePicture } from "./ProfilePicture";
import styled from "styled-components";
import { Icon } from "./Icon";
import { HeaderTitle } from "./PageContent";

interface HeaderProps {
    toggleMenu: () => void;
}

export const Header: React.FC<HeaderProps> = ({ toggleMenu }) => {
    const {
        userData
    } = useUserData();

    const user = userData?.users[0];

    return <>
        <HeaderDiv>
            <HeaderLinks>
                {/* <Icon name="arrow-more" onClick={toggleMenu} /> */}
                <Icon name="library" onClick={toggleMenu} />
                <HeaderTitle>Dewey</HeaderTitle>
            </HeaderLinks>
            <HeaderLinks>
                <PictureLink to="/profile">
                    {user && <ClickableProfilePicture 
                        pictureURL={user.pictureURL} 
                        name={user.name} 
                    /> }
                </PictureLink>
            </HeaderLinks>
        </HeaderDiv>
    </>
}

const PictureLink = styled(Link)`
    line-height: 0;
`;

const HeaderDiv = styled.div`
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    min-height: 51px;
    height: 51px;
    position: fixed;
    z-index: 1;
    background-image: linear-gradient(#f6f8fc, #f6f8fcd1);
    backdrop-filter: blur(2px);
`;

const HeaderLinks = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;

const ClickableProfilePicture = styled(ProfilePicture)`
    cursor: pointer;

    &:hover {
        opacity: 90%;
    }
`;
