import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { UserDataProvider } from './hooks/use_user_data';
import { SetupLibraryPage } from './SetupLibraryPage';
import { LibraryPage } from './LibraryPage';
import { BookPage } from './BookPage';
import { SearchPage } from './SearchPage';
import { RequireLibrary } from './RequireLibrary';
import { ExitPage } from './ExitPage';
import { FriendsPage } from './FriendsPage';
import { ProfilePage } from './ProfilePage';
import { WishlistPage } from './WishlistPage';
import { PrivacyPage } from './PrivacyPage';
import { TermsPage } from './TermsPage';
import { HomePage } from './HomePage';
import { AuthPage } from './AuthPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <SetupLibraryPage />,
  },
  {
    path: "/home",
    element: <HomePage />,
  },
  {
    path: "/setup",
    element: <SetupLibraryPage />,
  },
  {
    path: "/exit",
    element: <ExitPage />,
  },
  {
    path: "/privacy",
    element: <PrivacyPage />,
  },
  {
    path: "/terms",
    element: <TermsPage />,
  },
  {
    path: "/auth",
    element: <AuthPage />,
  },
  {
    path: "*",
    element: <RequireLibrary />,
    children: [
      {
        path: "library/:libraryId",
        element: <LibraryPage />,
      },
      {
        path: "library/",
        element: <LibraryPage />,
      },
      {
        path: "wishlist/",
        element: <WishlistPage />,
      },
      {
        path: "wishlist/:libraryId",
        element: <WishlistPage />,
      },
      {
        path: "books/:bookId",
        element: <BookPage />,
      },
      {
        path: "search",
        element: <SearchPage />,
      },    
      {
        path: "search/:term",
        element: <SearchPage />,
      },   
      {
        path: "friends",
        element: <FriendsPage />,
      },    
      {
        path: "profile",
        element: <ProfilePage />,
      },    
      {
        path: "profile/:libraryId/:userName",
        element: <ProfilePage />,
      }, 
    ]
  },
]);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserDataProvider>
        <RouterProvider router={router} />
      </UserDataProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
