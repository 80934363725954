import { useCallback, useEffect, useRef, useState } from "react";

async function loadScript(source: string) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = source;
        script.onload = resolve;
        script.onerror = reject;
        script.async = true;
        document.querySelector("body")?.appendChild(script);
    });
}

async function loadGSIScript() {
    return loadScript("https://accounts.google.com/gsi/client");
}

async function loadGAPIScript() {
    return loadScript("https://apis.google.com/js/api.js");
}

type TokenClient = any;

function getToken(tokenClient: TokenClient) {
    return async function actuallyGetToken(error: any) {
        if (
            error.result?.error?.code === 401 || 
            (error.result?.error?.code === 403
                && error.result?.error?.status === "PERMISSION_DENIED")
        ) {
            await new Promise((resolve, reject) => {
                try {
                    tokenClient.callback = (resp: any) => {
                        if (resp.error !== undefined) {
                            reject(resp);
                        }
                        resolve(resp);
                    };
                    console.log("Requesting access token");
                    tokenClient.requestAccessToken();
                } catch (err) {
                    if ((err as any).toString().includes("Failed to open popup window on url")) {
                        console.log("trying again...");
                        tokenClient.requestAccessToken();
                    }
                    reject(err);
                }
            });
        } else {
            console.log(error);
            throw new Error(error);
        }
    }
}

async function setup(
    discoveryDocumentURL: string, 
    scope: string, 
    clientId: string
) {
    await loadGAPIScript();
    await new Promise((resolve, reject) => {
        gapi.load('client', { callback: resolve, onerror: reject });
    });
    await gapi.client.init({})
    await (gapi.client as any).load(discoveryDocumentURL);
    await (gapi.client as any).load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
    await (gapi.client as any).load('https://www.googleapis.com/discovery/v1/apis/oauth2/v1/rest');

    await loadGSIScript();
    return await new Promise<TokenClient>((resolve, reject) => {
        const google = (window.google as any);
        try {
            const emailRaw = localStorage.getItem("email");
            const email = emailRaw && emailRaw !== "undefined" ? emailRaw : undefined;
            const tokenClient = google.accounts.oauth2.initTokenClient({
                client_id: clientId,
                scope: scope + " https://www.googleapis.com/auth/userinfo.profile",
                prompt: email ? '' : 'consent',
                callback: '',
                hint: email,
            });
            resolve(tokenClient);
        } catch (err) {
            reject(err);
        }
    });
}

export function useGoogleOauth(
    discoveryDocumentURL: string, 
    scope: string, 
    clientId: string,
    setIsAuthed: (isAuthed: boolean) => void
) {
    const [error] = useState<string | undefined>();
    const resolve = useRef<(c: TokenClient) => void>();
    const reject = useRef<(error: any) => void>();
    const tokenClient = useRef(new Promise((res, rej) => {
        resolve.current = res;
        reject.current = rej;
    }));

    useEffect(() => {
        setup(discoveryDocumentURL, scope, clientId)
            .then(resolve.current)
            .catch(reject.current);
    }, [scope, discoveryDocumentURL, clientId]);

    const getTokenNow = useCallback(async (error: any) => {
        const result = getToken(await tokenClient.current)(error);
        console.log("User authenticated.");
        setIsAuthed(true);
        return result;
    }, [tokenClient, setIsAuthed]);

    const signOut = useCallback(() => {
        gapi.auth.setToken(null as any);
        gapi.auth.signOut();
    }, []);

    const wait = async () => {
        await tokenClient.current;
    }

    return {
        error,
        tokenReady: tokenClient !== undefined,
        getToken: getTokenNow,
        signOut,
        wait
    }
}