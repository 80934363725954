import { useUserData } from "./hooks/use_user_data";
import { Navigate, useParams } from "react-router-dom";
import { BookCover } from "./BookCover";
import styled from "styled-components";  
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, PageTitle, PageSubtitle, Page, Link, LinkButton, DumbLink } from "./PageContent";
import { useState } from "react";
import { ReviewForm, ReviewFormData, REVIEW_SCORE_ICONS } from "./ReviewForm";
import { ProfilePicture } from "./ProfilePicture";
import moment from "moment";
import { ButtonIcon, Icon } from "./Icon";
import { ReviewData } from "./hooks/use_sheets";

async function fetchBookDetail(bookId: string | undefined) {
    if (bookId === undefined) {
        return {};
    }
    const response = await fetch(`https://openlibrary.org/works/${bookId}.json`);
    const bookDetail = await response.json();
    return bookDetail;
}

export const BookPage: React.FC = () => {
    const { 
        userId,
        getBookData,
        isBookInLibrary,
        addBookToLibrary,
        addBookToWishlist,
        isBookInWishlist,
        removeBookFromLibrary,
        whoHasIt,
        whoWantsIt,
        getFriendData,
        reviewsForBook,
        setReview,
        removeBookFromWishlist,
    } = useUserData();

    const { bookId } = useParams();

    const addBook = useMutation(() => {
        if (bookId === undefined) {
            throw new Error("No Book ID");
        };
        const bookData = getBookData(bookId);
        if (bookData === undefined) {
            throw new Error("No book data");
        };
        return addBookToLibrary(bookData)
    });

    const addWishlistBook = useMutation(() => {
        if (bookId === undefined) {
            throw new Error("No Book Id");
        };
        const bookData = getBookData(bookId);
        if (bookData === undefined) {
            throw new Error("No book data");
        };
        return addBookToWishlist(bookData)
    });

    const removeBook = useMutation(removeBookFromLibrary);
    const removeWishlistBook = useMutation(removeBookFromWishlist);

    const [isEditingReview, setIsEditingReview] = useState(false);

    const reviews = bookId ? reviewsForBook(bookId) : [];

    const [reviewData, setReviewData] = useState<ReviewFormData>(
        reviews.find(r => r.isMine) || {
            score: undefined,
            text: "",
        }
     );

    const submitReview = useMutation(async (review: ReviewFormData) => {
        if (bookId === undefined) {
            return;
        }
        await setReview({
            bookId,
            score: review.score,
            createdDate: new Date(),
            text: review.text?.trim(),
            isDeleted: false,
        });
        setIsEditingReview(false);
    });

    const removeReview = useMutation(async (review: ReviewData) => {
        await setReview({
            ...review,
            text: "",
            score: undefined
        });
        setIsEditingReview(false);
    });

    const markUnread = useMutation(async (review: ReviewData) => {
        await setReview({
            ...review,
            isDeleted: true
        });
    });

    const bookDetail = useQuery(["book-detail", bookId], () => fetchBookDetail(bookId));

    const [descriptionExpanded, setDescriptionExpanded] = useState(false);

    if (userId === undefined) {
        return <Navigate to='/setup' />;
    }

    if (bookId === undefined) {
        return <Navigate to='/' />;
    }

    const bookData = getBookData(bookId);
    const isInLibrary = isBookInLibrary(bookId);
    const isInWishlist = isBookInWishlist(bookId);

    const friendsWhoHaveIt = whoHasIt(bookId);
    const friendsWhoWantIt = whoWantsIt(bookId);

    const userReview = reviews.find(r => r.isMine);

    const userHasRead = userReview !== undefined;
    const userHasReviewed = reviews.some(r => r.isMine && (r.text || r.score));

    const isWaitingToShowReview = isEditingReview || submitReview.isLoading;

    const showReviewEditor = !userHasReviewed || isWaitingToShowReview;

    const description = bookDetail.data?.description 
        ? typeof bookDetail.data.description === "string"
        ? bookDetail.data.description 
        : bookDetail.data.description.value
        : undefined;
  
    return <Page auth={true}>
        <BookRow>
            <FlexBox>
                <BookCover 
                    title={bookData?.title || "Unknown"} 
                    coverImageUrl={bookData?.coverImageURL} 
                />
                <BookInfo>
                    <BookTitle>{ bookData?.title || "Unknown"}</BookTitle>
                    <BookAuthor>by { bookData?.authors || "Unknown" }</BookAuthor>
                    {bookData && 
                        <BookPublished>
                            First published { bookData.publishDate }
                        </BookPublished>
                    }
                    <DumbLink href={`https://openlibrary.org/works/${bookId}`}>View in Open Library</DumbLink>
                </BookInfo>
            </FlexBox>
        </BookRow>
        { description && <p>{descriptionExpanded ? description : description.slice(0, 150) + "..."} <LinkButton onClick={() => setDescriptionExpanded(!descriptionExpanded)}>Show {descriptionExpanded ? "Less" : "More"}</LinkButton></p>}
        <Buttons>
            {isInLibrary ? <>
                <Label><Icon name="library" size="sm" />In Library</Label>
                <Button 
                    onClick={() => removeBook.mutateAsync(bookId)}
                    isLoading={removeBook.isLoading}
                    disabled={removeBook.isLoading}
                >
                    <ButtonIcon name="cancel" />
                    Remove from Library
                </Button>
            </> : <>
                { isInWishlist ? <>
                    <Label><Icon name="heart" size="sm" />In Wishlist</Label>
                    <Button 
                        onClick={() => removeWishlistBook.mutateAsync(bookId)}
                        isLoading={removeWishlistBook.isLoading}
                        disabled={removeWishlistBook.isLoading}
                    ><ButtonIcon name="cancel" />Remove from Wishlist</Button>
                </> : <Button 
                    onClick={() => addWishlistBook.mutateAsync()}
                    isLoading={addWishlistBook.isLoading}
                    disabled={addWishlistBook.isLoading}
                ><ButtonIcon name="heart" />Add to Wishlist</Button>}
                <Button 
                    onClick={() => addBook.mutateAsync()} 
                    isLoading={addBook.isLoading}
                    disabled={addBook.isLoading}
                >
                    <ButtonIcon name="library" />
                    { isInWishlist ? "Move to Library" : "Add to Library" }
                </Button>
            </>}
            {!userHasRead && <Button 
                onClick={() => submitReview.mutate({})} 
                isLoading={submitReview.isLoading}
                disabled={submitReview.isLoading}
            >
                <ButtonIcon name="book" />
                I've Read This
            </Button>}
            {userReview && userHasRead && <Button 
                onClick={() => markUnread.mutate(userReview)} 
                isLoading={markUnread.isLoading}
                disabled={markUnread.isLoading}
            >
                <ButtonIcon name="book" />
                Oops, I Haven't Read It
            </Button>}
        </Buttons>
        { friendsWhoHaveIt.length > 0 && <Label>
            <Icon name="urgent-message" size="sm" />
            <span>
                {commaSeparate(
                    friendsWhoHaveIt.map(libraryId => {
                        const friendData = getFriendData(libraryId);
                        return <Link to={`/library/${libraryId}`}>
                            {friendData.users[0].name}
                        </Link>;
                    })
                )} { friendsWhoHaveIt.length > 1 ? "have" : "has"} this book
            </span>
        </Label>}
        { friendsWhoWantIt.length > 0 && <Label>
            <Icon name="urgent-message" size="sm" />
            <span>
                {commaSeparate(
                    friendsWhoWantIt.map(libraryId => {
                        const friendData = getFriendData(libraryId);
                        return <Link to={`/wishlist/${libraryId}`}>
                            {friendData.users[0].name}
                        </Link>;
                    })
                )} { friendsWhoHaveIt.length > 1 ? "want" : "wants"} this book
            </span>
        </Label>}
        <PageSubtitle>Reviews</PageSubtitle>
        {reviews.map((review) => {
            const friendData = getFriendData(review.createdBy);
            const user = friendData.users[0];
            const icon = review.score && REVIEW_SCORE_ICONS[review.score - 1];
            const kind = review.score || review.text ? "Reviewed" : "Marked read";
            return (!review.isMine || !isWaitingToShowReview) && <div key={review.createdBy}>
                <ReviewHeader>
                    <ProfilePicture name={user.name} pictureURL={user.pictureURL} />
                    {icon && <ReviewIconBadge><Icon name={icon.icon} style={{ width: "20px"}} /></ReviewIconBadge>}
                    <ReviewUserInfo>
                        <b>{friendData.users[0].name}</b>
                        <ReviewTime>{kind} {moment(review.createdDate).fromNow()} <LinkButton onClick={() => setIsEditingReview(true)}>Edit</LinkButton></ReviewTime>
                    </ReviewUserInfo>
                </ReviewHeader>
                {review.text?.trim() && <ReviewBody>
                    <i>{review.text}</i>
                </ReviewBody>}
            </div>
        })}
        { showReviewEditor && <>
            <ReviewForm review={reviewData} setReview={setReviewData} />
            <ReviewButtons>
                <Button 
                    onClick={() => submitReview.mutate(reviewData)}
                    isLoading={submitReview.isLoading}
                    disabled={
                        submitReview.isLoading 
                        || (!reviewData.score && !reviewData.text?.trim())
                    }
                >
                    Submit Review
                </Button>
                {userHasReviewed && <Button 
                    onClick={() => setIsEditingReview(false)}
                >Cancel</Button>}
                {userReview && userHasReviewed && <Button 
                    onClick={() => removeReview.mutate(userReview)}
                    isLoading={removeReview.isLoading}
                    disabled={removeReview.isLoading}
                >Delete</Button>}
            </ReviewButtons>
        </> }
    </Page>
}

function commaSeparate(list: React.ReactNode[]) {
    return list.map((node, index) => {
        return <>
            { node }
            { index <= list.length - 2 && list.length >= 3 ? ", " : "" }
            { index === list.length - 2 && list.length >= 2 ? " and " : "" }
        </>
    });
}

const ReviewIconBadge = styled.div`
    position: absolute;
    top: 24px;
    left: 21px;
    padding: 5px;
    width: 13px;
    height: 13px;
    filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .3));
`;

const BookTitle = styled(PageTitle)`
    margin-bottom: 0;
`;

const ReviewHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    position: relative;
`;

const ReviewUserInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const ReviewTime = styled.div`
    color: gray;
`;

const ReviewBody = styled.div`
    margin: 5px 0;
    padding-left: 10px;
    padding-top: 5px;
    position: relative;
    white-space: pre-wrap;

    &:before {
        position: absolute;
        content: '';
        background-color: #efefef;
        width: 4px;
        height: calc(100% - 5px);
        border-radius: 2px;
        left: 0px;
    }
`;

const ReviewButtons = styled.div`
    display: flex;
    gap: 10px;
    margin: 10px 0;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
`;

const FlexBox = styled.div`
    display: flex;
    width: 100%;
`;

const Label = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const BookRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const BookInfo = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const BookAuthor = styled.div`
`;

const BookPublished = styled.div`
    font-size: 12px;
    color: gray;
`;
