import { useAppData } from "./hooks/use_user_data";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";


export const ExitPage: React.FC = () => {
    const { logOut } = useAppData();

    useEffect(logOut);

    return <Navigate to="/setup" />
}